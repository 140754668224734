import { PaletteOptions } from '@core/theme/types/main';

export const colors = {
  kato: {
    salmon: {
      50: '#FFF0F1',
      100: '#FFE2E5',
      200: '#FFCAD2',
      300: '#FF9FAC',
      400: '#FF6981',
      500: '#FF4A6B', // Provided
      600: '#ED1143',
      700: '#C80839',
      800: '#A80937',
      900: '#8F0C35',
      950: '#500118',
    },
    yellow: {
      50: '#FFFAF0', // Provided
      100: '#FFEDCB', // Provided
      200: '#FFEC85',
      300: '#FFDC46',
      400: '#FFC91B',
      500: '#FFA700', // Provided
      600: '#E27E00',
      700: '#BB5702',
      800: '#984308',
      900: '#7C370B',
      950: '#481B00',
    },
    pink: {
      50: '#FFF9FD', // Provided
      100: '#FFE5F1', // Provided
      200: '#FECCE6',
      300: '#FFB1D9', // Provided
      400: '#FE68B1',
      500: '#F83C93',
      600: '#E81A70',
      700: '#CA0C55',
      800: '#A70D46',
      900: '#8B103D',
      950: '#550220',
    },
    red: {
      // Previously named "orange"
      50: '#FFF7F5', // Provided
      100: '#FEDCD6', // Provided
      200: '#FFCDC6',
      300: '#FFAB9E',
      400: '#FF7B67',
      500: '#FC573E', // Provided
      600: '#EA3418',
      700: '#C52810',
      800: '#A32411',
      900: '#862516',
      950: '#490F06', // Provided
    },
    teal: {
      50: '#EFF8F7', // Provided
      100: '#D7EDE8', // Provided
      200: '#ADE2D3',
      300: '#7CCCB8',
      400: '#50B19B',
      500: '#3CA48E', // Provided
      600: '#2A7769',
      700: '#256056',
      800: '#214E46',
      900: '#1F423B',
      950: '#0D2622',
    },
    green: {
      50: '#F2FCF4', // Provided
      100: '#E0F6E2', // Provided
      200: '#BEF4C6',
      300: '#8BEA9A',
      400: '#61DA75', // Provided
      500: '#2ABD43',
      600: '#1D9C33',
      700: '#1A7B2B',
      800: '#1A6127',
      900: '#175023',
      950: '#072C0F',
    },
    navy: {
      // Previously named "blue"
      50: '#F0F3FD',
      100: '#E3E9FC',
      200: '#CDD5F8',
      300: '#AEBAF3',
      400: '#8D97EC',
      500: '#7175E3',
      600: '#5C56D5',
      700: '#4F47BB',
      800: '#403C97',
      900: '#393679',
      950: '#201E42', // Provided
    },
    grey: {
      50: '#F0F3FD',
      100: '#F6F6F8',
      200: '#EDECF1',
      300: '#D2D2DD',
      400: '#B7B8C7',
      500: '#A1A2B3',
      600: '#6D6F85',
      700: '#505267',
      800: '#3D4054',
      900: '#252839',
      950: '#161729',
    },
    solid: {
      black: '#000000',
      white: '#FFFFFF',
    },
  },
  blue: {
    lightest: '#F5FAFF',
    lighter: '#E6F2FF',
    light: '#E6F2FF',
    lightish: '#CBE3FF',
    main: '#487EF4',
    medium: '#0077ff',
    darkish: '#024795',
    dark: '#324DFD',
    darker: '#11284F',
    darkest: '#182847',
  },
  grey: {
    lightest: '#EAEBEA', // angular - $faintGrey
    lighter: '#f1f3f5', // angular - $lightergrey
    light: '#DDE1E6', // angular - $lightgrey
    lightish: '#BFC5D1', // angular - $mediumgrey / $mediumishgrey. Same as #b5bbc6
    main: '#858D9D', // angular -  $grey. Same as #828D9E
    darkish: '#6B788E', // angular - $darkgrey-two
    dark: '#7B879B', // angular - $darkgrey
    darker: '#4C5970', // angular - $darkergrey
    darkest: '#0a2147', // angular - darkishgrey

    //
    icon: '#A6B1BC', // angular - $icongrey
    body: '#F6F7F9', // angular - $bodygrey. Same as #f5f7f9
    fainter: '#D9DDE4', // angular - $faintergrey. Same as #D9DBE2
  },
  red: {
    lightest: '#FEF3F2',
    lighter: '#FBE2E5',
    light: '#FF7F90',
    lightish: '',
    main: '#F14C5D',
    darkish: '#ED1C24',
    dark: '#DB4950',
    darker: '',
    darkest: '#B42318',
  },
  green: {
    lightest: '#D2F1E1',
    lighter: '',
    light: '#9CE1BD',
    lightish: '#61D076',
    main: '#39C37B',
    darkish: '#3AC454',
    dark: '',
    darker: '#1D864F',
    darkest: '#085E44',
  },
  white: {
    lightest: '#FFFFFF',
    light: '#FCFCFC', // angular - $offwhite
    main: '#FAFAFB', // angular - $mediumwhite
  },
  black: {
    lightest: '#19273C', // Same as #1D2639
    lighter: '',
    light: '',
    lightish: '',
    main: '',
    darkish: '',
    dark: '#1D2639',
    darker: '#001234', // Same as #001334, #101828
    darkest: '#000000',
  },
  purple: {
    lightest: '',
    lighter: '#EEF0FF', //same as #EBEEFF
    light: '#EBE1F8',
    lightish: '',
    main: '#50218C',
    darkish: '',
    dark: '#2a283d',
    darker: '',
    darkest: '',
  },
  orange: {
    lightest: '',
    lighter: '#FFF2E7',
    light: '#FCEADB',
    lightish: '#FF9A3E',
    main: '#F2944D',
    darkish: '#D9A200',
    dark: '#F0802C',
    darker: '#C27127',
    darkest: '',
  },
};

export const initializePalette = (config?: PaletteOptions): PaletteOptions => {
  return {
    primary: {
      main: colors.kato.navy[950],
      dark: colors.blue.darkish,
      light: colors.blue.light,
    },
    secondary: {
      main: colors.white.lightest,
      light: colors.blue.main,
    },
    ...config,
  };
};
