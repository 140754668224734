import { Overrides } from '@core/theme/types/main';

export const initializeTableCellOverrides = (config?: Overrides['MuiTableCell']): Overrides['MuiTableCell'] => {
  return {
    root: {
      padding: `4px 16px`,
    },
    ...config,
  };
};
