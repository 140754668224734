import { Overrides } from '@core/theme/types/main';
import { fontFamily } from '@core/theme/units/typography';

import { initializeAccordionOverrides } from './accordion';
import { initializeAccordionDetailsOverrides } from './accordion-details';
import { initializeAccordionSummaryOverrides } from './accordion-summary';
import { initializeBackdropOverrides } from './backdrop';
import { initializeButtonOverrides } from './button';
import { initializeCheckboxOverrides } from './checkbox';
import { initializeChipOverrides } from './chip';
import { initializeDialogOverrides } from './dialog';
import { initializeDrawerOverrides } from './drawer';
import { initializeFormControlLabelOverrides } from './form-control-label';
import { initializeIconButtonOverrides } from './icon-button';
import { initializeInputOverrides } from './input';
import { initializeInputBaseOverrides } from './input-base';
import { initializeLinkOverrides } from './link';
import { initializeListOverrides } from './list';
import { initializeListItemOverrides } from './list-item';
import { initializeMenuOverrides } from './menu';
import { initializeOutlinedInputOverrides } from './outlined-input';
import { initializePaperOverrides } from './paper';
import { initializeRadioOverrides } from './radio';
import { initializeTabOverrides } from './tab';
import { initializeTableCellOverrides } from './table-cell';
import { initializeTabsOverrides } from './tabs';
import { initializeTooltipOverrides } from './tooltip';
import { colors } from '../palette';

export const initializeOverrides = (config?: Overrides): Overrides => {
  return {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#FFFFFF',
        },
        '#root': {
          height: '100%',
        },
        a: {
          cursor: 'pointer',
          textDecoration: 'none',
        },
        input: {
          '&::-ms-clear, &::-ms-reveal': {
            display: 'none',
          },

          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
          },
        },
        span: {
          fontWeight: 400,
          fontFamily: fontFamily,
        },
        h3: {
          fontSize: 16,
          color: colors.black.darker,
        },
        '.MuiFormHelperText-root': {
          display: 'none',
        },
        '.icon': {
          blockSize: '24px',

          '&::before': {
            fontFamily: 'ae-marketplace !important',
            fontStyle: 'normal !important',
            fontWeight: 'normal !important',
            fontVariant: 'normal !important',
            textTransform: 'none !important',
            speak: 'none',
            lineHeight: 1,
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          },
        },
        '.iconlg': {
          blockSize: '63px',

          '&::before': {
            fontFamily: 'as-large-icons !important',
            fontStyle: 'normal !important',
            fontWeight: 'normal !important',
            fontVariant: 'normal !important',
            textTransform: 'none !important',
            speak: 'none',
            lineHeight: 1,
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          },
        },
      },
    },
    MuiAccordion: initializeAccordionOverrides(config?.MuiAccordion),
    MuiAccordionSummary: initializeAccordionSummaryOverrides(config?.MuiAccordionSummary),
    MuiAccordionDetails: initializeAccordionDetailsOverrides(config?.MuiAccordionDetails),
    MuiChip: initializeChipOverrides(config?.MuiChip),
    MuiTab: initializeTabOverrides(config?.MuiTab),
    MuiTabs: initializeTabsOverrides(config?.MuiTabs),
    MuiButton: initializeButtonOverrides(config?.MuiButton),
    MuiDrawer: initializeDrawerOverrides(config?.MuiDrawer),
    MuiMenu: initializeMenuOverrides(config?.MuiMenu),
    MuiLink: initializeLinkOverrides(config?.MuiLink),
    MuiOutlinedInput: initializeOutlinedInputOverrides(config?.MuiOutlinedInput),
    MuiInputBase: initializeInputBaseOverrides(config?.MuiInputBase),
    MuiTooltip: initializeTooltipOverrides(config?.MuiTooltip),
    MuiList: initializeListOverrides(config?.MuiList),
    MuiListItem: initializeListItemOverrides(config?.MuiListItem),
    MuiPaper: initializePaperOverrides(config?.MuiPaper),
    MuiTableCell: initializeTableCellOverrides(config?.MuiTableCell),
    MuiDialog: initializeDialogOverrides(config?.MuiDialog),
    MuiRadio: initializeRadioOverrides(config?.MuiRadio),
    MuiCheckbox: initializeCheckboxOverrides(config?.MuiCheckbox),
    MuiInput: initializeInputOverrides(config?.MuiInput),
    MuiBackdrop: initializeBackdropOverrides(config?.MuiBackdrop),
    MuiFormControlLabel: initializeFormControlLabelOverrides(config?.MuiFormControlLabel),
    MuiIconButton: initializeIconButtonOverrides(config?.MuiIconButton),
    ...config,
  };
};
