import { ThemeOptions, TypographyOptions } from '@core/theme/types/main';

export const fontFamily = 'Inter, sans-serif';
// export const fontFamily = 'sofia-pro, Open Sans, Helvetica, Arial, sans-serif';

export const initializeTypography = (config?: ThemeOptions['typography']): TypographyOptions => {
  return {
    fontFamily,
    ...config,
  };
};
