import { Overrides } from '@core/theme/types/main';

export const initializeMenuOverrides = (config?: Overrides['MuiMenu']): Overrides['MuiMenu'] => {
  return {
    paper: {
      maxHeight: 350,
    },
    ...config,
  };
};
