import { Overrides } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';

export const initializeListItemOverrides = (config?: Overrides['MuiListItem']): Overrides['MuiListItem'] => {
  return {
    root: {
      '&.Mui-selected': {
        backgroundColor: colors.blue.lightest,

        '&:hover': {
          backgroundColor: colors.blue.lightest,
        },
      },
    },
    button: {
      '&:hover': {
        backgroundColor: colors.blue.lightest,
      },
    },
    ...config,
  };
};
