import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';
export const navMenuWidth = 210;
export const navMenuWidthCollapsed = 24;

export const styles = ({ zIndex }: Theme) =>
  createStyles({
    root: {},

    sideBar: {
      height: '100%',
      minHeight: 640,
      position: 'fixed',
      transition: 'all 0.3s ease',
      width: navMenuWidth,
      zIndex: zIndex.appBar,
    },

    app: {
      backgroundColor: colors.grey.body,
      height: '100vh',
      marginLeft: navMenuWidth,
      transition: 'all 0.3s ease',
      zIndex: 1,
    },
    appCollapsed: {
      marginLeft: navMenuWidthCollapsed,
    },
    sideNavigationCollapsed: {
      width: navMenuWidthCollapsed,
    },
  });
