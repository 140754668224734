import { Overrides } from '@core/theme/types/main';

export const initializeAccordionDetailsOverrides = (
  config?: Overrides['MuiAccordionDetails']
): Overrides['MuiAccordionDetails'] => {
  return {
    root: {},
    ...config,
  };
};
