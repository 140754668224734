import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import ReactGA from 'react-ga4';
import { IntercomProvider } from 'react-use-intercom';

import { ConfigContext } from '@core/contexts/ConfigContext';
import { Layout } from '@core/layout';
import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { ErrorHandler } from '@shared/components/error-handler';

import { styles } from './app.styles';
import { PrivateRoutes } from './routing/PrivateRoutes';

interface AppState {
  appCrash: boolean;
}

export type AppProps = WithStyles<typeof styles>;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Filler
);

class App extends React.Component<AppProps, AppState> {
  static contextType = ConfigContext;
  declare context: React.ContextType<typeof ConfigContext>;
  state = { appCrash: false };

  componentDidMount() {
    const {
      config: { user },
    } = this.context;

    ReactGA.initialize([
      {
        trackingId: this.context.config.googleAnalyticsTrackingId,
        gtagOptions: {
          send_page_view: false,
          user_id: user.id,
        },
      },
    ]);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ appCrash: true });
  }

  render() {
    const { classes } = this.props;
    const {
      config: { intercom_hash, intercomEnabled, intercomId, user },
      error,
    } = this.context;

    if (this.state.appCrash || error) {
      return (
        <ErrorHandler
          heading={error || `Sorry, we're having a few technical troubles right now.`}
          classes={{ root: classes.errorHandler }}
        />
      );
    }

    return (
      <IntercomProvider
        appId={intercomId}
        autoBoot={intercomEnabled}
        autoBootProps={{
          email: user.email,
          name: user.name,
          userHash: intercom_hash,
          userId: user.id.toString(),
        }}
      >
        <Layout>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PrivateRoutes />
          </MuiPickersUtilsProvider>
        </Layout>
      </IntercomProvider>
    );
  }
}

export default withStyles(styles)(App);
