import { Overrides } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';

export const initializeBackdropOverrides = (config?: Overrides['MuiBackdrop']): Overrides['MuiBackdrop'] => {
  return {
    root: {
      backgroundColor: alpha(colors.black.darker, 0.4),
    },
    ...config,
  };
};
