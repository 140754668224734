import { Theme } from '@core/theme/types/main';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    errorHandler: {
      minHeight: '100vh',
      padding: spacing(0, 8),
    },
  });
