import { Overrides } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';

export const initializeChipOverrides = (config?: Overrides['MuiChip']): Overrides['MuiChip'] => {
  return {
    root: {},
    clickable: {
      backgroundColor: `${colors.white.lightest} !important`,
    },
    ...config,
  };
};
