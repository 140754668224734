import { Overrides } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';

export const initializePaperOverrides = (config?: Overrides['MuiPaper']): Overrides['MuiPaper'] => {
  return {
    root: {},
    elevation1: {
      boxShadow: '0 2px 3px 0 rgb(0 18 52 / 10%)',
      border: `1px solid ${colors.grey.light}`,
    },
    elevation2: {
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.07)',
      border: 'none',
    },
    elevation3: {
      boxShadow: 'none',
      border: `1px solid ${colors.grey.lightest}`,
      backgroundColor: colors.grey.body,
    },
    ...config,
  };
};
