import { Overrides } from '@core/theme/types/main';

import { colors } from '../palette';

export const initializeTooltipOverrides = (config?: Overrides['MuiTooltip']): Overrides['MuiTooltip'] => {
  return {
    tooltip: {
      color: colors.white.lightest,
      backgroundColor: colors.black.darkest,
    },
    ...config,
  };
};
