import { Overrides } from '@core/theme/types/main';

export const initializeIconButtonOverrides = (config?: Overrides['MuiIconButton']): Overrides['MuiIconButton'] => {
  return {
    root: {
      padding: 0,
    },
    ...config,
  };
};
