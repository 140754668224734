import { t } from 'i18next';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';

export interface UnsavedChangesContextValue {
  hasChanges: boolean;
  setHasChanges: UseStateSetter<boolean>;
  message: string;
  setMessage: UseStateSetter<string>;
}

export interface UseUnsavedChangesValue {
  changesPrompt: ReactNode;
  hasChanges: boolean;
  setHasChanges: UseStateSetter<boolean>;
}

export const UnsavedChangesContext = createContext<UnsavedChangesContextValue>({} as UnsavedChangesContextValue);

export const useUnsavedChanges = (message?: string): UseUnsavedChangesValue => {
  const context = useContext(UnsavedChangesContext);

  useEffect(() => {
    context.setMessage(message || t('you_have_unsaved_changes_do_you_want_to_continue_without_saving_question'));
  }, [message]);

  return useMemo(
    () => ({
      changesPrompt: <Prompt when={context.hasChanges} message={context.message} />,
      hasChanges: context.hasChanges,
      setHasChanges: context.setHasChanges,
    }),
    [context.hasChanges, context.message, context.setHasChanges]
  );
};

export const UnsavedChangesProvider: FC = ({ children }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [message, setMessage] = useState('');

  // Leaving the page via external link (Angular pages)
  useEffect(() => {
    if (!hasChanges) {
      return;
    }

    const unloadAbort = new AbortController();

    window.addEventListener(
      'beforeunload',
      (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
      },
      { signal: unloadAbort.signal }
    );

    return () => unloadAbort.abort();
  }, [hasChanges]);

  return (
    <UnsavedChangesContext.Provider
      value={{
        hasChanges,
        setHasChanges,
        message,
        setMessage,
      }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};
