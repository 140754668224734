import { Overrides } from '@core/theme/types/main';

export const initializeAccordionSummaryOverrides = (
  config?: Overrides['MuiAccordionSummary']
): Overrides['MuiAccordionSummary'] => {
  return {
    root: {},
    ...config,
  };
};
