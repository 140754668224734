import i18next from 'i18next';
import { createContext, FC, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { configQueryKeys, useConfigFetchQuery } from '@apis/configApi';
import { queryClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { RootState } from '@redux/reducers/rootReducer';
import { Loading } from '@shared/components/loading';
import { Config } from '@shared/models/config';


export type SetConfig = (config: Config) => void;

export type UseConfigState = () => [Config, SetConfig];

export interface ConfigContextValue {
  config: Config;
  error: string;
  setConfig: SetConfig;
}

export const ConfigContext = createContext<ConfigContextValue>({} as ConfigContextValue);

export const useConfig = () => {
  return useContext(ConfigContext).config;
};

export const useConfigState: UseConfigState = () => {
  const { config, setConfig } = useContext(ConfigContext);
  return [config, setConfig];
};

export const ConfigProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  // Legacy stuff
  const reduxUser = useSelector((state: RootState) => state.config.user);

  const setConfig: SetConfig = useCallback(
    (configData) => queryClient.setQueryData(configQueryKeys.show().queryKey, configData),
    []
  );

  const configQuery = useConfigFetchQuery({
    onSuccess: (config) => {
      window.AS = config;

      i18next.addResources('en', 'core', config.constants.localisation.translations);

      dispatch(configActions.setUser(config.user));
    },
  });

  useEffect(() => {
    if (configQuery.isSuccess && reduxUser) {
      setConfig({ ...configQuery.data, user: reduxUser });
    }
  }, [reduxUser]);

  if (configQuery.isLoading) {
    return <Loading absolute />;
  }

  const value = { config: {} as Config, error: '', setConfig };

  if (configQuery.isSuccess) {
    value.config = configQuery.data;
  } else if (configQuery.isError) {
    value.error = configQuery.error?.message;
  }

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
