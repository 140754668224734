import { Overrides } from '../../types/main';
import { colors } from '../palette';

export const initializeTabsOverrides = (config?: Overrides['MuiTabs']): Overrides['MuiTabs'] => {
  return {
    root: {},
    indicator: {
      backgroundColor: colors.blue.dark,
    },
    ...config,
  };
};
