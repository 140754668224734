import { Overrides } from '@core/theme/types/main';

import { colors } from '../palette';

export const initializeInputBaseOverrides = (config?: Overrides['MuiInputBase']): Overrides['MuiInputBase'] => {
  return {
    root: {
      '& $disabled': {
        color: colors.black.darkest,
      },
    },
    inputMultiline: {
      resize: 'vertical',
    },
    ...config,
  };
};
