import { Overrides } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';

export const initializeRadioOverrides = (config?: Overrides['MuiRadio']): Overrides['MuiRadio'] => {
  return {
    root: {},
    colorSecondary: {
      color: colors.black.darkest,

      '&.Mui-checked': {
        color: colors.black.darkest,
      },

      '&.Mui-disabled': {
        color: alpha(colors.black.darkest, 0.4),
      },
    },
    ...config,
  };
};
