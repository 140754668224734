import { Overrides } from '@core/theme/types/main';

export const initializeInputOverrides = (config?: Overrides['MuiInput']): Overrides['MuiInput'] => {
  return {
    root: {
      fontSize: 14,
    },
    ...config,
  };
};
