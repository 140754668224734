import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { AxiosResponse } from 'axios';

import { getHTTPClient, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@core/http-client';
import { getQueriesAsSearch } from '@shared/utils/common';

import {
  UsersPayload,
  UsersResponse,
  UsersUpdateProfileImagePayload,
  UsersUpdateProfileImageResponse,
} from './usersApi.types';

const $http = getHTTPClient();

export const usersQueryKeys = createQueryKeys('users', {
  all: null,
  list: (payload: UsersPayload) => ({
    queryKey: [payload.query],
    queryFn: async () => (await $http.get<UsersResponse>(`/users${getQueriesAsSearch(payload.query)}`)).data,
  }),
});

export type UsersQueryKeys = inferQueryKeys<typeof usersQueryKeys>;

export const useUsersListQuery = (payload: UsersPayload, options?: UseQueryOptions<UsersResponse>) =>
  useQuery<UsersQueryKeys['list']['queryKey'], UsersResponse>({
    ...usersQueryKeys.list(payload),
    keepPreviousData: true,
    ...options,
  });

export const useUsersUpdateProfileImageMutation = (
  opts?: UseMutationOptions<UsersUpdateProfileImagePayload, UsersUpdateProfileImageResponse>
) =>
  useMutation({
    ...opts,
    mutationFn: async (payload: UsersUpdateProfileImagePayload) =>
      (
        await $http.post<UsersUpdateProfileImagePayload['body'], AxiosResponse<UsersUpdateProfileImageResponse>>(
          `user/${payload.id}/profile_pic`,
          payload.body
        )
      ).data,
  });
