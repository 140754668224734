import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { getHTTPClient, useQuery, UseQueryOptions } from '@core/http-client';
import { Config } from '@shared/models/config';

const $http = getHTTPClient(false);

export const configQueryKeys = createQueryKeys('config', {
  all: null,
  show: () => ({
    queryKey: [''],
    queryFn: async () => (await $http.get<Config>(`/config`)).data,
  }),
});
export type ConfigQueryKeys = inferQueryKeys<typeof configQueryKeys>;

export const useConfigFetchQuery = (options?: UseQueryOptions<Config>) =>
  useQuery<ConfigQueryKeys['show']['queryKey'], Config>({
    ...configQueryKeys.show(),
    keepPreviousData: true,
    ...options,
  });
