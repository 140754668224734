import { debounce, Typography } from '@material-ui/core';
import { LoadScript } from '@react-google-maps/api';
import cx from 'classnames';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useConfig } from '@core/contexts/ConfigContext';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { layoutActions } from '@redux/reducers/layout/layoutReducer';
import { layoutSelectors } from '@redux/selectors/layout';
import { Loading } from '@shared/components/loading';
import { SideNavigation } from '@shared/components/side-navigation';

import { styles } from './Layout.styles';

export type LayoutProps = WithStyles<typeof styles>;

const collapsedWidth = 700;

const LayoutComponent: FC<LayoutProps> = ({ classes, children }) => {
  const dispatch = useDispatch();

  const { constants } = useConfig();
  const { navMenuCollapsed } = useSelector(layoutSelectors.getLayoutState);

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResizeScreen, 50));

    return () => window.removeEventListener('resize', handleResizeScreen);
  }, []);

  const handleResizeScreen = () => {
    if (window.innerWidth < collapsedWidth) {
      dispatch(layoutActions.toggleCollapsedNavMenu(true));

      return;
    }

    if (window.innerWidth >= collapsedWidth && navMenuCollapsed) {
      dispatch(layoutActions.toggleCollapsedNavMenu(false));
    }
  };

  const handleCollapse = () => {
    dispatch(layoutActions.toggleCollapsedNavMenu(!navMenuCollapsed));
  };

  return (
    <LoadScript
      id="google-map-script"
      googleMapsApiKey={constants.GOOGLE_MAPS_HTTP_API}
      loadingElement={<Loading absolute />}
    >
      <Typography component="div">
        <SideNavigation
          collapsed={navMenuCollapsed}
          classes={{
            root: classes.sideBar,
            rootCollapsed: classes.sideNavigationCollapsed,
          }}
          onCollapse={handleCollapse}
        />
        <div className={cx(classes.app, { [classes.appCollapsed]: navMenuCollapsed })}>{children}</div>
      </Typography>
    </LoadScript>
  );
};

export const Layout = withStyles(styles)(LayoutComponent);
